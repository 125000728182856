//import logo from '';
import '../App.css';
import React from "react";
import ReactDOM from 'react-dom/client';


import NavBar from "../NavBar.js"; 
import HeadApps from "../HeadApps.js"; 
import {Link, NavLink} from  'react-router-dom';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';

// dropdown menu
import Dropdown from 'react-dropdown';
import 'react-dropdown/style.css';

// importation fonction Tabs
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';

// dropdown option
const options = [
  'Activer', 'Désactiver', 'Maintenir','Editer','Supprimer'
];

const defaultOption = options[0];

const style = {
  position: 'absolute',
  top: '20%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

function Flixrooms() 
{   


  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);


 const [value, setValue] = React.useState('1');

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
      <div className="group_div">
      <HeadApps></HeadApps>
      <NavBar></NavBar>
      <div className="grid-10 apps_container">
      
      <div className="group_div">

        <div className="grid-12">
        <ul className="flixTab_filariane">
          <li><NavLink to="/Flixrooms"><span>Liste  FlixRoom</span></NavLink><i className="demo-icon  icon-right-open-4 "></i></li>
          <li><span>Vue FlixRoom</span></li>
        </ul>
        </div>
      </div>

          <div className="group_div flixitem_group">
             <div className="flix_item flixroom_vue_item">

              <div className="flix_bloc2 float_left">
                <div className="grid-12 flixroom_title_item">
                  FLIXROMM GENESIS 1
                </div>
                <div className="flixroom_code_item"><span>FX-CERT109080</span></div>
                <div className="grid-12">
                  <div className="flixroom_status_item"><span className="flix_encours">Encours</span></div>
                </div>
              </div>


              <div className="flix_bloc2 float_left bloc_padding2">
                <div className="flixroom_adress_item"><i className="demo-icon icon-location-3"></i><span>47, Cantersteen  B.P: 1000 Bruxelles-Belgique</span></div>
              </div>

              <div className="flix_bloc2 float_left bloc_padding2">
                  <div className="flix_date_create"><i className="demo-icon icon-calendar-empty"></i><span><small>Crée</small>27/01/2031</span></div>
              </div>


              <div className="flix_bloc2 float_left bloc_padding2">
                <div className="flixroom_avatar"><div className="flix_avatar_small" ></div></div>
                <div className="flixroom_name"><small>Propriétaire</small><span>Dalice J.</span></div>
              </div>

              <div className="flix_bloc4 float_right ">
                      <Dropdown options={options}   onChange={this} placeholder="Action" className="flix_dropdown_action" />                    
                
              </div>

             </div> 


             <div className="flix_item flixroom_vue_item">

              <div className="flix_bloc1 float_left">
                <div className="grid-12 flixroom_title_item">
                  Flixrooms Licence
                </div>
                <div className="flixroom_code_item"><span>FX-LICENSE-ROOM-480-210127</span></div>
                
              </div>

              <div className="flix_bloc2 float_left bloc_padding2">

                  <div className="flixroom_status_item"><span className="flix_active">Active</span></div>
              </div>
              <div className="flix_bloc2 float_left bloc_padding2">
               
                  <div className="flix_date_create"><i className="demo-icon icon-clock-7"></i><span><small>Expire</small>27/01/2031</span></div>
               
              </div>

              <div className="flix_bloc4 float_right ">
                      <Dropdown options={options}  onChange={this} placeholder="Action" className="flix_dropdown_action" />                    
      
              </div>

             </div>

             <div>
             <Tabs className="flix_tabs_flixroom flix_tabs">
              <TabList>
                <Tab><i className="demo-icon icon-calendar-8"></i><span>Calendrier</span></Tab>
                <Tab ><i className="demo-icon icon-right-open-4"></i><span>FlixRoom Equipement</span></Tab>
                <Tab label="Disabled" disabled><i className="demo-icon icon-right-open-4"></i><span>FlixRoom Equipement</span></Tab>
                <Tab><i className="demo-icon icon-right-open-4"></i><span>FlixRoom Réseaux</span></Tab>
                <Tab><i className="demo-icon icon-right-open-4"></i><span>Maintenance</span></Tab>
              </TabList>

              <TabPanel >
                <h2>Calendrier</h2>
              </TabPanel>
              <TabPanel>
                <h2>Liste des équipement dispo</h2>
              </TabPanel>
              <TabPanel>
                <h2>Liste des équipement</h2>
              </TabPanel>
              <TabPanel>
                <h2>Paramettres Réseaux</h2>
              </TabPanel>
              <TabPanel>
                <h2>Console log maintenance</h2>
              </TabPanel>
            </Tabs>

           



      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Nouvelle FlixRoom
          </Typography>
          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
            Contenu de la page en pop
          </Typography>
          <Typography id="modal-modal-footer" >
            <div>Bouton</div>
          </Typography>
        </Box>
      </Modal>
    </div>


          </div>

      </div>
      
      </div>
      );


   } 


export default Flixrooms;