//import logo from '';
import '../App.css';
import React from "react";
import ReactDOM from 'react-dom/client';

import NavBar from "../NavBar.js"; 
import HeadApps from "../HeadApps.js"; 



function Tableaubord() 
{   
  return (
      <div className="group_div">
      <HeadApps></HeadApps>
      <NavBar></NavBar>
      <div className="grid-10 apps_container">Tableau de bord</div>
      </div>
      );


   } 


export default Tableaubord;