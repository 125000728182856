
import '../App.css';
import React,{useState} from "react"; 
import { BrowserRouter, Route } from "react-router-dom";


import NavBar from "../NavBar.js"; 
import HeadApps from "../HeadApps.js"; 
import {Link, NavLink} from  'react-router-dom';
import Button from "@mui/material/Button";
// Table générale
import { DataGrid, GridRowsProp, GridColDef} from '@mui/x-data-grid';

// datepicker

// defintion des donnée du tableau
const rows: GridRowsProp = [
  { id: 1, col1: 'Conversation en cours', col2: 'Conversation' ,col3:'Encours',col4:'10/04/2024 12:23:30'},
  { id: 2, col1: 'Gilles vient de connecter', col2: 'Talents' ,col3:'Encours',col4:'10/04/2024 12:23:30'},
  { id: 3, col1: 'Recommandattion', col2: 'Recommandattion' ,col3:'Encours',col4:'10/04/2024 12:23:30'},
];

// click su rune ligne
const handleEvent: GridEventListener<'rowClick'> = (
  params, // GridRowParams
  event, // MuiEvent<React.MouseEvent<HTMLElement>>
  details, // GridCallbackDetails
) => {
  alert(` ${params.row.col1} `);
};

// definition des cologne
const columns: GridColDef[] = [
  { field: 'col1', headerName: 'Notifications', width: 250 },
  { field: 'col2', headerName: 'Type ', width: 150 },
  { field: 'col3', headerName: 'Statut', width: 150 },
  { field: 'col4', headerName: 'Date Notification', width: 150 },{
    field: "action",
    headerName: "Action",
    sortable: false,
    renderCell: (params) => {
      const onClick = (e) => {
        e.stopPropagation(); // don't select this row after clicking

          alert(` ${params.row.col1} `);
      };

      return <Button onClick={onClick}><i className="demo-icon icon-eye-6"></i></Button>;
    }
  },
];

function Notifications() 
{   

  const [checkboxSelection, setCheckboxSelection] = React.useState(true);
  const [disableMultipleRowSelection, setDisableMultipleRowSelection] =
  React.useState(false);

  return (

      <div className="group_div">
      <HeadApps></HeadApps>
      <NavBar></NavBar>
      <div className="grid-10 apps_container">

      <div className="group_div">
        <div className="grid-12">
          <div className="grid-1">
             </div>
          <div className="grid-10"></div>
        </div>
        <div className="grid-12">

        <ul className="flixTab_onglet">
          <li><NavLink to="/Tableaubord"><span>Toutes</span></NavLink></li>
          <li><NavLink to="/Tableaubord"><span>Lu</span></NavLink></li>
          <li><NavLink to="/Tableaubord"><span>Non Lu</span></NavLink></li>
        </ul>
        
        </div>
        <div className="grid-12">
          <div className="flix_pagine_ul">

          </div>
          <div className="flix_page_search ">
                <div className="flix_page_input"><i className="demo-icon icon-calendar-8"></i><input type="text" placeholder="Filtre Date" /></div>
          </div>
        </div>
      </div>
      <div className="flix_table">
     <DataGrid onRowClick={handleEvent}  rows={rows} columns={columns}  checkboxSelection={checkboxSelection}
          disableMultipleRowSelection={disableMultipleRowSelection} />
      </div>

      </div>
      </div>
    );


   } 

export default Notifications;