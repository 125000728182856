import '../App.css';
import React from "react";
import ReactDOM from 'react-dom/client';

import NavBar from "../NavBar.js"; 
import HeadApps from "../HeadApps.js"; 
import {Link, NavLink} from  'react-router-dom';


// dropdown menu
import Dropdown from 'react-dropdown';
import 'react-dropdown/style.css';

// dropdown option
const options = [
  'Activer', 'Désactiver', 'Supprimer'
];
const defaultOption = options[0];

function Entreprise() 
{   
  return (
      <div className="group_div">
      <HeadApps></HeadApps>
      <NavBar></NavBar>
      <div className="grid-10 apps_container">
         <div className="group_div">
              <div className="grid-12">
                <div className="grid-2">
                  <button className="flix_btadd_item"><i className="demo-icon icon-plus-circled"></i><span>Nouvelle Entreprise</span></button>
                </div>
                <div className="grid-10"></div>
              </div>
              <div className="grid-12">
              <ul className="flixTab_onglet">
                <li><NavLink to="/Tableaubord"><span>Toutes</span></NavLink></li>
                <li><NavLink to="/Tableaubord"><span>Active</span></NavLink></li>
                <li><NavLink to="/Tableaubord"><span>En cours</span></NavLink></li>
                <li><NavLink to="/Tableaubord"><span>Supprimé</span></NavLink></li>
              </ul>
              </div>
              <div className="grid-12">
                <div className="flix_pagine_ul">
                  <button><i className="demo-icon icon-left-open-4"></i></button>
                  <span className="flix_pagine_result">1</span>/<span className="flix_pagine_result">2</span>
                  <button><i className="demo-icon icon-right-open-4"></i></button>
                </div>
                <div className="flix_page_search ">
                      <div className="flix_page_input"><i className="demo-icon icon-search-8"></i><input type="text" placeholder="Entreprise" /></div>
                      <div className="flix_page_input"><i className="demo-icon icon-calendar-8"></i><input type="text" placeholder="Filtre Date" /></div>
                </div>
              </div>
            </div>


          <div className="flixitem_group group_div">
         
             <div className="flix_item propritaire_item">
                <div className="flix_pack_groupItem">
                  <div className="flix_input">
                  <input type="checkbox" value="" />
                  </div>
                  <div className="flix_pack_talentItem">
                    pack <span>STANDARD</span>
                  </div>
                  <div className="flix_pack_statustItem">
                      <span className="flix_active_pack">actif</span>
                  </div>
                </div>

                <div className="flix_text_group">

                <div className="flix_m_avatar">
                  
                </div>
                <div className="grid-12 flixroom_title_item">
                 BNP Paris bas
                </div>

                  <div className="flixroom_code_item"><span>banque</span></div>

                  <div className="flixroom_status_item"><span className="flix_encours">Encours</span></div>
                  </div>

                   <div className="group_div flix_group1">
                    <div className="flix_date_expire"><i className="demo-icon icon-location-3"></i><span>Bruxelles, Belgique</span></div>
                    <div className="flix_date_create"><i className="demo-icon  icon-hourglass-3"></i><span>100<small>Talents</small></span></div>
                  </div>

                  <div className="group_div flix_group1">
                  <div className="flix_date_expire"><i className="demo-icon icon-clock-7"></i><span><small>Expire</small>27/01/2031</span></div>
                  <div className="flix_date_create"><i className="demo-icon icon-calendar-empty"></i><span><small>Crée</small>27/01/2031</span></div>
                </div>

          
                <div className="group_div flix_group">
                      <Dropdown options={options}  value={defaultOption} onChange={this} placeholder="Action" className="flix_dropdown_action" />
                      <NavLink className="flix_see_item">Voir Plus</NavLink>
                    
                  </div> 
             </div>  


             <div className="flix_item propritaire_item">
                <div className="flix_pack_groupItem">
                  <div className="flix_input">
                  <input type="checkbox" value="" />
                  </div>
                  <div className="flix_pack_talentItem">
                    pack <span>STANDARD</span>
                  </div>
                  <div className="flix_pack_statustItem">
                      <span className="flix_active_pack">actif</span>
                  </div>
                </div>

                <div className="flix_text_group">
                
                <div className="flix_m_avatar">
                  
                </div>
                <div className="grid-12 flixroom_title_item">
                 AXA
                </div>

                  <div className="flixroom_code_item"><span>Corporate</span></div>

                  <div className="flixroom_status_item"><span className="flix_active">Actif</span></div>
                  </div>

                  <div className="group_div flix_group1">
                    <div className="flix_date_expire"><i className="demo-icon icon-location-3"></i><span>Bruxelles, Belgique</span></div>
                    <div className="flix_date_create"><i className="demo-icon  icon-hourglass-3"></i><span>100<small>Talents</small></span></div>
                  </div>

                  <div className="group_div flix_group1">
                  <div className="flix_date_expire"><i className="demo-icon icon-clock-7"></i><span><small>Expire</small>27/01/2031</span></div>
                  <div className="flix_date_create"><i className="demo-icon icon-calendar-empty"></i><span><small>Crée</small>27/01/2031</span></div>
                </div>

          
                <div className="group_div flix_group">
                      <Dropdown options={options}  value={defaultOption} onChange={this} placeholder="Action" className="flix_dropdown_action" />
                      <NavLink className="flix_see_item">Voir Plus</NavLink>
                    
                  </div> 
             </div> 

             <div className="flix_item propritaire_item">
             
                <div className="flix_pack_groupItem">
                  <div className="flix_input">
                  <input type="checkbox" value="" />
                  </div>
                  <div className="flix_pack_talentItem">
                    pack <span>STANDARD</span>
                  </div>
                  <div className="flix_pack_statustItem">
                      <span className="flix_active_pack">actif</span>
                  </div>
                </div>

                <div className="flix_text_group">
                
                <div className="flix_m_avatar">
                  
                </div>
                <div className="grid-12 flixroom_title_item">
                 AXA
                </div>

                  <div className="flixroom_code_item"><span>Corporate</span></div>

                  <div className="flixroom_status_item"><span className="flix_annule">Annulé</span></div>
                  </div>

                   <div className="group_div flix_group1">
                    <div className="flix_date_expire"><i className="demo-icon icon-location-3"></i><span>Bruxelles, Belgique</span></div>
                    <div className="flix_date_create"><i className="demo-icon  icon-hourglass-3"></i><span>100<small>Talents</small></span></div>
                  </div>

                  <div className="group_div flix_group1">
                  <div className="flix_date_expire"><i className="demo-icon icon-clock-7"></i><span><small>Expire</small>27/01/2031</span></div>
                  <div className="flix_date_create"><i className="demo-icon icon-calendar-empty"></i><span><small>Crée</small>27/01/2031</span></div>
                </div>

          
                <div className="group_div flix_group">
                      <Dropdown options={options}  value={defaultOption} onChange={this} placeholder="Action" className="flix_dropdown_action" />
                      <NavLink className="flix_see_item">Voir Plus</NavLink>
                    
                  </div> 
             </div> 

             <div className="flix_item propritaire_item">

                <div className="flix_pack_groupItem">
                  <div className="flix_input">
                  <input type="checkbox" value="" />
                  </div>
                  <div className="flix_pack_talentItem">
                    pack <span>FLIX</span>
                  </div>
                  <div className="flix_pack_statustItem">
                      <span className="flix_resilie_pack">Resilié</span>
                  </div>
                </div>

                <div className="flix_text_group">
                
                <div className="flix_m_avatar">
                  
                </div>
                <div className="grid-12 flixroom_title_item">
                 AXA
                </div>

                  <div className="flixroom_code_item"><span>Corporate</span></div>

                  <div className="flixroom_status_item"><span className="flix_supprime">Supprimé</span></div>
                  </div>
                
                 <div className="group_div flix_group1">
                    <div className="flix_date_expire"><i className="demo-icon icon-location-3"></i><span>Bruxelles, Belgique</span></div>
                    <div className="flix_date_create"><i className="demo-icon  icon-hourglass-3"></i><span>100<small>Talents</small></span></div>
                  </div>

                  <div className="group_div flix_group1">
                  <div className="flix_date_expire"><i className="demo-icon icon-clock-7"></i><span><small>Expire</small>27/01/2031</span></div>
                  <div className="flix_date_create"><i className="demo-icon icon-calendar-empty"></i><span><small>Crée</small>27/01/2031</span></div>
                </div>

          
                <div className="group_div flix_group">
                      <Dropdown options={options}  value={defaultOption} onChange={this} placeholder="Action" className="flix_dropdown_action" />
                      <NavLink className="flix_see_item">Voir Plus</NavLink>
                    
                  </div> 
             </div> 
          </div>

      </div>
      </div>
      );


   } 


export default Entreprise;