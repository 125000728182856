//import logo from '';
import '../App.css';
import React from "react";
import ReactDOM from 'react-dom/client';

import NavBar from "../NavBar.js"; 
import HeadApps from "../HeadApps.js"; 
import {Link, NavLink} from  'react-router-dom';

// dropdown menu
import Dropdown from 'react-dropdown';
import 'react-dropdown/style.css';

// dropdown option
const options = [
  'Activer', 'Désactiver', 'Supprimer','Assigner'
];
const defaultOption = options[0];

function Talent() 
{   
  return (
      <div class="group_div">
      <HeadApps></HeadApps>
      <NavBar></NavBar>
      <div class="grid-10 apps_container">
        <div className="group_div">
        <div className="grid-12">
          <div className="grid-2">
            <button className="flix_btadd_item"><i className="icon_svg_nav1 icon_assign"></i><span>Assigner Talent</span></button>
          </div>
          <div className="grid-2">
            <button className="flix_btadd_item"><i className="demo-icon icon-plus-circled"></i><span>Nouveau Talent</span></button>
          </div>
          <div className="grid-8"></div>
        </div>
        <div className="grid-12">
        <ul className="flixTab_onglet">
          <li><NavLink to="/Tableaubord"><span>Toutes</span></NavLink></li>
          <li><NavLink to="/Tableaubord"><span>En cours</span></NavLink></li>
          <li><NavLink to="/Tableaubord"><span>Achevés</span></NavLink></li>
          <li><NavLink to="/Tableaubord"><span>Désactivées</span></NavLink></li>
          <li><NavLink to="/Tableaubord"><span>Supprimées</span></NavLink></li>
        </ul>
        </div>
        <div className="grid-12">
          <div className="flix_pagine_ul">
            <button><i className="demo-icon icon-left-open-4"></i></button>
            <span className="flix_pagine_result">1</span>/<span className="flix_pagine_result">2</span>
            <button><i className="demo-icon icon-right-open-4"></i></button>
          </div>
          <div className="flix_page_search ">
                <div className="flix_page_input"><i className="demo-icon icon-search-8"></i><input type="text" placeholder="Nom(s)/Prenom(s)" /></div>
                <div className="flix_page_input"><i className="demo-icon icon-calendar-8"></i><input type="text" placeholder="Filtre Date" /></div>
                <div className="flix_page_input"><i className="demo-icon icon-location-3"></i><input type="text" placeholder="Filtre FlixRoom" /></div>
          </div>
        </div>
      </div>

          <div className="flixitem_group">
          
             <div className="flix_item talent_item">
                <div className="flix_pack_groupItem">
                  <div className="flix_input">
                  <input type="checkbox" value="" />
                  </div>
                  <div className="flix_pack_talentItem">
                    pack <span>STANDARD</span>
                  </div>
                  <div className="flix_pack_statustItem">
                      <span className="flix_active_pack">actif</span>
                  </div>
                </div>
                <div className="flix_text_group">
                      <div className="flix_m_avatar"></div>
                      <div className="grid-12 flixroom_title_item"> Franck</div>
                      <div className="flixroom_code_item"><span>90CD-dzds</span></div>
                      <div className="flixroom_status_item"><span className="flix_supprime">Supprimé</span></div>
                      <div className="flixtalent_status"><i className="demo-icon icon-record-1 flix_phase_item1"></i>phase<span> Découverte</span></div>
                  </div>

              
                  <div className="group_div flix_group">
                    <div className="flix_phase_global">
                    <div className="flix_phase_group">
                      <div className="flix_phase_progress1"></div>
                    </div>
                    <div className="flix_phase_percent">70%</div>
                  </div>

                      <Dropdown options={options}  onChange={this} placeholder="Action" className="flix_dropdown_action" />
                      <NavLink className="flix_see_item">Voir Plus</NavLink>
                    
                  </div> 
             </div> 
        
             <div className="flix_item talent_item">
                <div className="flix_pack_groupItem">
                  <div className="flix_input">
                  <input type="checkbox" value="" />
                  </div>
                  <div className="flix_pack_talentItem">
                    pack <span>STANDARD</span>
                  </div>
                  <div className="flix_pack_statustItem">
                      <span className="flix_resilie_pack">résilié</span>
                  </div>
                </div>
                <div className="flix_text_group">
                      <div className="flix_m_avatar"></div>
                      <div className="grid-12 flixroom_title_item"> Franck</div>
                      <div className="flixroom_code_item"><span>90CD-dzds</span></div>
                      <div className="flixroom_status_item"><span className="flix_supprime">Supprimé</span></div>
                      <div className="flixtalent_status"><i className="demo-icon icon-record-1 flix_phase_item2"></i>phase<span> Travailleur</span></div>
                  </div>

              
                  <div className="group_div flix_group">
                    <div className="flix_phase_global">
                    <div className="flix_phase_group">
                      <div className="flix_phase_progress2"></div>
                    </div>
                    <div className="flix_phase_percent">70%</div>
                  </div>

                      <Dropdown options={options}   onChange={this} placeholder="Action" className="flix_dropdown_action" />
                      <NavLink className="flix_see_item">Voir Plus</NavLink>
                    
                  </div> 
             </div> 

             <div className="flix_item talent_item">
                <div className="flix_pack_groupItem">
                  <div className="flix_input">
                  <input type="checkbox" value="" />
                  </div>
                  <div className="flix_pack_talentItem">
                    pack <span>STANDARD</span>
                  </div>
                  <div className="flix_pack_statustItem">
                      <span className="flix_active_pack">actif</span>
                  </div>
                </div>
                <div className="flix_text_group">
                      <div className="flix_m_avatar"></div>
                      <div className="grid-12 flixroom_title_item"> Franck</div>
                      <div className="flixroom_code_item"><span>90CD-dzds</span></div>
                      <div className="flixroom_status_item"><span className="flix_active">Achevé</span></div>
                      <div className="flixtalent_status"><i className="demo-icon icon-record-1 flix_phase_item3"></i>phase<span> Talent</span></div>
                  </div>

              
                  <div className="group_div flix_group">
                    <div className="flix_phase_global">
                    <div className="flix_phase_group">
                      <div className="flix_phase_progress3"></div>
                    </div>
                    <div className="flix_phase_percent">100%</div>
                  </div>

                      <Dropdown options={options}   onChange={this} placeholder="Action" className="flix_dropdown_action" />
                      <NavLink className="flix_see_item">Voir Plus</NavLink>
                    
                  </div> 
             </div> 

          </div>
      </div>
      </div>
      );


   } 


export default Talent;