
import '../App.css';
import React from "react"; 
import { BrowserRouter, Route, Link } from "react-router-dom"; 

import NavBar from "../NavBar.js"; 
import HeadApps from "../HeadApps.js"; 

function Utilisateurs() 
{   
  return (

      <div class="group_div">
      <HeadApps></HeadApps>
      <NavBar></NavBar>
      <div class="grid-10 apps_container">Utilisateur</div>
      </div>
    );


   } 

export default Utilisateurs;