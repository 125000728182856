import './App.css';

import { React, useSate,useState,State} from "react";
import ReactDOM from 'react-dom/client';

import { CognitoIdentityProviderClient, InitiateAuthCommand,  } from "@aws-sdk/client-cognito-identity-provider"; // ES Modules import

import {  CognitoUserPool,  CognitoUserAttribute, CognitoUser, } from 'amazon-cognito-identity-js';

import * as Yup from 'yup';
import { useNavigate } from "react-router-dom";

var AmazonCognitoIdentity = require('amazon-cognito-identity-js');
const REACT_APP_USERPOOL_ID="eu-north-1_OSOHqE2cX";
const REACT_APP_APPCLIENT_ID="2bj4vhcdo861u3g4v5rsle3vj0";

const userPool = new CognitoUserPool({
  UserPoolId: REACT_APP_USERPOOL_ID,
  ClientId: REACT_APP_APPCLIENT_ID,
});



function Forgetpass() {

 const [formValue, setFormValue]=useState({change_key1:'',change_key2:'',change_flixuser:''});

const changeInput = (e) =>{
const {name,value}=e.target;
  setFormValue({...formValue,[name]:value});
 }

 
const keySubmit = async(e)=>{
e.preventDefault();
const changeValue={change_key1:formValue.change_key1,change_key2:formValue.change_key2,change_flixuser:formValue.change_flixuser};

console.log(changeValue);

}



  return (
    <div className="container">
    <div className="App flix_login">

        <img src="./logoflix.svg" className="App-logo" alt="logo" />
        <h2>Flix<span>Admin</span> <span className="flix_grisTxt">Platform</span></h2>
      <form onSubmit={keySubmit}>
        <p>
         <input type="text" placeholder="E-mail" name="change_flixuser"   value={formValue.change_flixuser} onChange={changeInput}/>
        </p>
        <p>
         <input type="password" placeholder="Password" name="change_key1"   value={formValue.change_key1} onChange={changeInput}/>
        </p>
        <p>
         <input type="password" placeholder="Repeat password" name="change_key2"    value={formValue.change_key2} onChange={changeInput}/>
        </p>
    
         <button className="flixbutton button_Active" onSubmit={keySubmit} >Modifier mot de passe</button>
      
      
        </form>
    </div>
    </div>
  );
}

export default Forgetpass;