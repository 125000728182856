//import logo from '';
import './App.css';
import React from "react"; 
import {Link, NavLink} from  'react-router-dom';


function Navbar() 
{   
  return (
      <div className="grid-2 flix_nav"><nav>
    <ul>
    <li><NavLink to="/Tableaubord" className={({ isActive }) => {   return isActive ? "flix_navactive" : "";}} ><i className="demo-icon icon-th-large-2" ></i><span>Tableaubord</span></NavLink></li>
    <li><NavLink to="/Flixrooms"  className={({ isActive }) => {   return isActive ? "flix_navactive" : "";}} ><i className="icon_svg_nav icon_flixrooms"></i><span>Flixrooms</span></NavLink></li>
    <li><NavLink to="/Proprietaire"  className={({ isActive }) => {   return isActive ? "flix_navactive" : "";}}><i className="icon_svg_nav icon_proprietaire"></i><span>Propriétaire</span></NavLink></li>
    <li><NavLink to="/Talent"  className={({ isActive }) => {   return isActive ? "flix_navactive" : "";}}><i className="icon_svg_nav icon_talent"></i><span>Talents</span></NavLink></li>
    <li><NavLink to="/Conversation"  className={({ isActive }) => {   return isActive ? "flix_navactive" : "";}}><i className="icon_svg_nav icon_conversation"></i><span>Conversations</span></NavLink></li>
    <li><NavLink to="/Calendrier"  className={({ isActive }) => {   return isActive ? "flix_navactive" : "";}}><i className="demo-icon icon-calendar-8"></i><span>Calenrdrier</span></NavLink></li>
    <li><NavLink to="/Recommandation"  className={({ isActive }) => {   return isActive ? "flix_navactive" : "";}}><i className="icon_svg_nav icon_recommandation"></i><span>Recommandations</span></NavLink></li>
    <li><NavLink to="/Care"  className={({ isActive }) => {   return isActive ? "flix_navactive" : "";}}><i className="icon_svg_nav icon_care"></i><span>Care</span></NavLink></li>
    <li><NavLink to="/Entreprise"  className={({ isActive }) => {   return isActive ? "flix_navactive" : "";}}><i className="icon_svg_nav icon_entreprise"></i><span>Entreprises</span></NavLink></li>
    <li><NavLink to="/Notifications"  className={({ isActive }) => {   return isActive ? "flix_navactive" : "";}}><i className="demo-icon icon-bell-5"></i><span>Notifications</span></NavLink></li>
    </ul></nav>
    <div className="design_nav"></div>

    <ul>
      <li><Link to="/Tableaubord"><i className="demo-icon  icon-cog-6"></i><span>Parametres</span></Link></li>
      <li className="flix_button_other1"><Link to="/Logout"><i className="demo-icon  icon-logout-3"></i><span>D&eacute;connexion</span></Link></li>
    </ul>
      </div>); } 

export default Navbar;

