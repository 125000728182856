//import logo from '';
import '../App.css';
import React from "react";
import ReactDOM from 'react-dom/client';

import NavBar from "../NavBar.js"; 
import HeadApps from "../HeadApps.js"; 
import {Link, NavLink} from  'react-router-dom';

// dropdown menu
import Dropdown from 'react-dropdown';
import 'react-dropdown/style.css';

// dropdown option
const options = [
  'Activer', 'Désactiver', 'Supprimer'
];
const defaultOption = options[0];


function Proprietaire() 
{   
  return (
      <div class="group_div">
      <HeadApps></HeadApps>
      <NavBar></NavBar>
      <div class="grid-10 apps_container">
        
      <div className="group_div">
        <div className="grid-12">
          <div className="grid-1">
            <button className="flix_btadd_item"><i className="demo-icon icon-plus-circled"></i><span>Nouveau Proprietaire</span></button>
          </div>
          <div className="grid-10"></div>
        </div>
        <div className="grid-12">
        <ul className="flixTab_onglet">
          <li><NavLink to="/Tableaubord"><span>Toutes</span></NavLink></li>
          <li><NavLink to="/Tableaubord"><span>Active</span></NavLink></li>
          <li><NavLink to="/Tableaubord"><span>Désactivées</span></NavLink></li>
          <li><NavLink to="/Tableaubord"><span>Encours</span></NavLink></li>
          <li><NavLink to="/Tableaubord"><span>Supprimées</span></NavLink></li>
        </ul>
        </div>
        <div className="grid-12">
          <div className="flix_pagine_ul">
            <button><i className="demo-icon icon-left-open-4"></i></button>
            <span className="flix_pagine_result">1</span>/<span className="flix_pagine_result">2</span>
            <button><i className="demo-icon icon-right-open-4"></i></button>
          </div>
          <div className="flix_page_search ">
                <div className="flix_page_input"><i className="demo-icon icon-search-8"></i><input type="text" placeholder="Nom" /></div>
                <div className="flix_page_input"><i className="demo-icon icon-calendar-8"></i><input type="text" placeholder="Filtre Date" /></div>
          </div>
        </div>
      </div>
      <div className="flixitem_group group_div">
         
             <div className="flix_item propritaire_item">

                <div className="flix_text_group">

                <div className="flix_m_avatar">
                  
                </div>
                <div className="grid-12 flixroom_title_item">
                 AXA
                </div>

                  <div className="flixroom_code_item"><span>Corporate</span></div>

                  <div className="flixroom_status_item"><span className="flix_encours">Encours</span></div>
                  </div>

                  <div className="group_div flix_group1">
                    <div className="flix_date_expire"><i className="demo-icon icon-location-3"></i><span>4<small>FlixRooms</small></span></div>
                    <div className="flix_date_create"><i className="demo-icon i icon-user-1"></i><span>10<small>Agents</small></span></div>
                  </div>

                  <div className="group_div flix_group1">
                  <div className="flix_date_expire"><i className="demo-icon icon-clock-7"></i><span><small>Expire</small>27/01/2031</span></div>
                  <div className="flix_date_create"><i className="demo-icon icon-calendar-empty"></i><span><small>Crée</small>27/01/2031</span></div>
                </div>

          
                <div className="group_div flix_group">
                      <Dropdown options={options}   onChange={this} placeholder="Action" className="flix_dropdown_action" />
                      <NavLink className="flix_see_item">Voir Plus</NavLink>
                    
                  </div> 
             </div>  


             <div className="flix_item propritaire_item">

                <div className="flix_text_group">
                
                <div className="flix_m_avatar">
                  
                </div>
                <div className="grid-12 flixroom_title_item">
                 AXA
                </div>

                  <div className="flixroom_code_item"><span>Corporate</span></div>

                  <div className="flixroom_status_item"><span className="flix_active">Actif</span></div>
                  </div>

                  <div className="group_div flix_group1">
                    <div className="flix_date_expire"><i className="demo-icon icon-location-3"></i><span>4<small>FlixRooms</small></span></div>
                    <div className="flix_date_create"><i className="demo-icon  icon-user-1"></i><span>10<small>Agents</small></span></div>
                  </div>

                  <div className="group_div flix_group1">
                  <div className="flix_date_expire"><i className="demo-icon icon-clock-7"></i><span><small>Expire</small>27/01/2031</span></div>
                  <div className="flix_date_create"><i className="demo-icon icon-calendar-empty"></i><span><small>Crée</small>27/01/2031</span></div>
                </div>

          
                <div className="group_div flix_group">
                      <Dropdown options={options}   onChange={this} placeholder="Action" className="flix_dropdown_action" />
                      <NavLink className="flix_see_item">Voir Plus</NavLink>
                    
                  </div> 
             </div> 

             <div className="flix_item propritaire_item">

                <div className="flix_text_group">
                
                <div className="flix_m_avatar">
                  
                </div>
                <div className="grid-12 flixroom_title_item">
                 AXA
                </div>

                  <div className="flixroom_code_item"><span>Corporate</span></div>

                  <div className="flixroom_status_item"><span className="flix_annule">Annulé</span></div>
                  </div>

                  <div className="group_div flix_group1">
                    <div className="flix_date_expire"><i className="demo-icon icon-location-3"></i><span>4<small>FlixRooms</small></span></div>
                    <div className="flix_date_create"><i className="demo-icon  icon-user-1"></i><span>10<small>Agents</small></span></div>
                  </div>

                  <div className="group_div flix_group1">
                  <div className="flix_date_expire"><i className="demo-icon icon-clock-7"></i><span><small>Expire</small>27/01/2031</span></div>
                  <div className="flix_date_create"><i className="demo-icon icon-calendar-empty"></i><span><small>Crée</small>27/01/2031</span></div>
                </div>

          
                <div className="group_div flix_group">
                      <Dropdown options={options}   onChange={this} placeholder="Action" className="flix_dropdown_action" />
                      <NavLink className="flix_see_item">Voir Plus</NavLink>
                    
                  </div> 
             </div> 

             <div className="flix_item propritaire_item">

                <div className="flix_text_group">
                
                <div className="flix_m_avatar">
                  
                </div>
                <div className="grid-12 flixroom_title_item">
                 AXA
                </div>

                  <div className="flixroom_code_item"><span>Corporate</span></div>

                  <div className="flixroom_status_item"><span className="flix_supprime">Supprimé</span></div>
                  </div>

                  <div className="group_div flix_group1">
                    <div className="flix_date_expire"><i className="demo-icon icon-location-3"></i><span>4<small>FlixRooms</small></span></div>
                    <div className="flix_date_create"><i className="demo-icon  icon-user-1"></i><span>10<small>Agents</small></span></div>
                  </div>

                  <div className="group_div flix_group1">
                  <div className="flix_date_expire"><i className="demo-icon icon-clock-7"></i><span><small>Expire</small>27/01/2031</span></div>
                  <div className="flix_date_create"><i className="demo-icon icon-calendar-empty"></i><span><small>Crée</small>27/01/2031</span></div>
                </div>

          
                <div className="group_div flix_group">
                      <Dropdown options={options}   onChange={this} placeholder="Action" className="flix_dropdown_action" />
                      <NavLink className="flix_see_item">Voir Plus</NavLink>
                    
                  </div> 
             </div> 


      </div>
      </div>
      </div>
      );


   } 


export default Proprietaire;