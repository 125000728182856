//import logo from '';
import '../App.css';
import React, {Component,useState} from "react";
import ReactDOM from 'react-dom/client';

import NavBar from "../NavBar.js"; 
import HeadApps from "../HeadApps.js"; 

import $ from 'jquery';

import {
  Calendar,
  DateLocalizer,
  momentLocalizer,
  globalizeLocalizer,
  move,
  Views,
  Navigate,
  components,
} from 'react-big-calendar';

import moment from "moment";
import 'moment/locale/fr';

import "react-big-calendar/lib/css/react-big-calendar.css";


moment.locale("fr");

const localizer = momentLocalizer(moment)
//DEMO EVENTS DATA
/*
export const events =  [
    {
        id: 1,
        title: 'Board meeting',
        location: 'Dhaka, Bangladesh',
        start: new Date(2024, 9, 4, 9, 0, 0),
        end: new Date(2024, 9, 4, 13, 0, 0)
    },
    {
        id: 2,
        title: 'Team lead meeting',
        location: 'Rajshahi, Bangladesh',
        start: new Date(2024, 3, 15, 9, 0, 0),
        end: new Date(2024, 3, 16, 13, 0, 0)
    },
];
*/
export const events = [{ 
  title:"Conversation CX-DDD-EDEFSFSFFSFSF-FSFS ",
  start:"2024-04-13T01:00:00",
  end:"2024-04-13T23:59:59",
  description: 'Description Conversation',
  category :'conversation',
  allDay:false,
  resource: {
    userId:"ec1f6076-9fcc-48c6-b0e9-e39dbc29557x",
    eventType:"visaExtensionWorkPermit" 
    },
},
{ 
  title:"recommandation DDD ",
  start:"2024-04-23T00:00:00+07:00",
  end:"2024-04-23T23:59:59+07:00",
  description: 'description de la recommandation',
  category :'recommandation',
  allDay:true,
  resource: {
    userId:"actrf6076-9fcc-99cdf-c7f9-039gxw29557v",
    eventType:"visaExtensionWorkPermit" 
    },
}];


        //onSelectSlot={(e) => handleSelect(e)}
        //onSelectEvent={(e) => handleSelectedEvent(e)}


const handleSelectedEvent = (event) => {

 alert("ID : "+event.resource.userId+"-"+event.description);

};




// JQuery Déclaration
$(function() {

$( ".rbc-event-content" ).before( "<div class='flix_bull_calendar'><i class='demo-icon icon-circle'></i></div>" );

});


function Calendrier() 
{   
  return (
      <div className="group_div">
      <HeadApps></HeadApps>
      <NavBar></NavBar>
      <div className="grid-10 apps_container">
    
            <Calendar
           localizer={localizer}
            onSelectEvent={(event) => handleSelectedEvent(event)}
            popup
           showMultiDayTimes
            eventPropGetter={event => ({className: 'category-' + event.category.toLowerCase()})}
            events={events}
            startAccessor="start"
            endAccessor="end"
            style={{ width: "100%", height: 600 }}
            />
      </div>
      </div>
      );


   } 


export default Calendrier;