import './App.css';

import { React, useSate,useState,State} from "react";

import { CognitoIdentityProviderClient, InitiateAuthCommand,  } from "@aws-sdk/client-cognito-identity-provider"; // ES Modules import

import {  CognitoUserPool,  CognitoUserAttribute, CognitoUser, } from 'amazon-cognito-identity-js';

import { useNavigate } from "react-router-dom";

var AmazonCognitoIdentity = require('amazon-cognito-identity-js');
const REACT_APP_USERPOOL_ID="us-east-1_rOLcyaBUL";
const REACT_APP_APPCLIENT_ID="5bbpdtcioefd6fm3cnn1gct3rq";

const userPool = new CognitoUserPool({
  UserPoolId: REACT_APP_USERPOOL_ID,
  ClientId: REACT_APP_APPCLIENT_ID,
});


function Logout() {

// var form
 const [formValue, setFormValue]=useState({change_code:'',change_flixuser:'',change_key1:''});

// get Value input form
const changeInput = (e) =>{
const {name,value}=e.target;
  setFormValue({...formValue,[name]:value});
 }

const confirmSubmit = async(e) => {
e.preventDefault();

const changeValue={change_code:formValue.change_code,change_flixuser:formValue.change_flixuser,change_key1:formValue.change_key1};

const authenticationDetails = new AmazonCognitoIdentity.AuthenticationDetails({
      Username: changeValue.change_flixuser,
      Password: changeValue.change_key1,
    });

 var userData = {
        Username : changeValue.change_flixuser,
        Pool : userPool
    };

    var cognitoUser = new AmazonCognitoIdentity.CognitoUser(userData);

    cognitoUser.confirmRegistration(changeValue.change_code, true, (err, data) => {
      if (err) {
     
        alert("Couldn't verify account");
      } else {
        
        alert('Account verified successfully');
        
      }
    });




}


  return (
    <div className="container">
    <div className="App flix_out">
       
    </div>
    </div>
  );
}

export default Logout;


