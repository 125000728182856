import React from 'react';
import ReactDOM from 'react-dom/client';
//import { createBrowserRouter, RouterProvider, Route} from 'react-router-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';

import { createBrowserRouter,RouterProvider ,Route } from 'react-router-dom';

import HeadApps from "./HeadApps"; 
import Navbar from "./NavBar"; 
import Layout from "./pages/Layout"; 
import Tableaubordpage from "./pages/Tableaubord"; 
import FlixroomsPage from "./pages/Flixrooms"; 
import FlixroomsVue from "./pages/Flixrooms_vue"; 
import ProprietairePage from "./pages/Proprietaire"; 
import Talent from "./pages/Talent"; 
import Conversation from "./pages/Conversation"; 
import Calendrier from "./pages/Calendrier"; 
import Recommandation from "./pages/Recommandation"; 
import Notifications from "./pages/Notifications"; 
import Utilisateurs from "./pages/Utilisateurs";
import Care from "./pages/Care";
import Entreprise from "./pages/Entreprise";
import Forgetpasspage from "./Forgetpass"; 
import Confirme from "./Confirme"; 
import Logout from "./Logout"; 
import Registerpage from "./pages/Register";

const router = createBrowserRouter([ 
       {
        path:"/",
        element:<App />
       },
       {
        path:"Tableaubord",
        element:<Tableaubordpage />
       },
       {
        path:"Flixrooms",
        element:<FlixroomsPage />
       },
       {
        path:"FlixroomsVue",
        element:<FlixroomsVue />
       },
       {
        path:"Proprietaire",
        element:<ProprietairePage />
       },
       {
        path:"Talent",
        element:<Talent />
       },
       {
        path:"Conversation",
        element:<Conversation />
       },
       {
        path:"Calendrier",
        element:<Calendrier />
       },
       {
        path:"Recommandation",
        element:<Recommandation />
       },
       {
        path:"Notifications",
        element:<Notifications />
       },
       {
        path:"Forgetpass",
        element:<Forgetpasspage />
       },
       {
        path:"Utilisateurs",
        element:<Utilisateurs />
       },
       {
        path:"Confirme",
        element:<Confirme />
       },
       {
        path:"Logout",
        element:<Logout />
       },
       {
        path:"Care",
        element:<Care />
       },
       {
        path:"Entreprise",
        element:<Entreprise />
       },
       {
        path:"Register",
        element:<Registerpage />
       },
  ]);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <RouterProvider router={router} />
);

// default code
/*const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);*/

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
