import './App.css';

import { React, useSate,useState,State} from "react";
import ReactDOM from 'react-dom/client';

import { CognitoIdentityProviderClient, InitiateAuthCommand,  } from "@aws-sdk/client-cognito-identity-provider"; // ES Modules import

import {  CognitoUserPool,  CognitoUserAttribute, CognitoUser, } from 'amazon-cognito-identity-js';

import { useNavigate } from "react-router-dom";

var AmazonCognitoIdentity = require('amazon-cognito-identity-js');
const REACT_APP_USERPOOL_ID="us-east-1_rOLcyaBUL";
const REACT_APP_APPCLIENT_ID="5bbpdtcioefd6fm3cnn1gct3rq";

const userPool = new CognitoUserPool({
  UserPoolId: REACT_APP_USERPOOL_ID,
  ClientId: REACT_APP_APPCLIENT_ID,
});




function Confirme() {

 const [formValue, setFormValue]=useState({change_code:'',change_flixuser:'',change_key1:''});

const changeInput = (e) =>{
const {name,value}=e.target;
  setFormValue({...formValue,[name]:value});
 }

 
const confirmSubmit = async(e)=>{
e.preventDefault();
const changeValue={change_code:formValue.change_code,change_flixuser:formValue.change_flixuser,change_key1:formValue.change_key1};

 const authenticationDetails = new AmazonCognitoIdentity.AuthenticationDetails({
      Username: changeValue.change_flixuser,
      Password: changeValue.change_key1,
    });

 var userData = {
        Username : changeValue.change_flixuser,
        Pool : userPool
    };

    var cognitoUser = new AmazonCognitoIdentity.CognitoUser(userData);

    cognitoUser.confirmRegistration(changeValue.change_code, true, (err, data) => {
      if (err) {
        console.log(err);
        alert("Couldn't verify account");
      } else {
        console.log(data);
        alert('Account verified successfully');
        
      }
    });
console.log(userPool);


}


  return (
    <div className="container">
    <div className="App flix_login">
        <img src="./logoflix.svg" className="App-logo" alt="logo" />
        <h2>Flix<span>Admin</span> <span className="flix_grisTxt">Platform</span></h2>
      <form onSubmit={confirmSubmit}>
        <p>
         <input type="text" placeholder="E-mail" name="change_flixuser"   value={formValue.change_flixuser} onChange={changeInput}/>
        </p>
        <p>
         <input type="text" placeholder="confirme Code" name="change_code"   value={formValue.change_code} onChange={changeInput}/>
        </p>
        <p>
         <input type="password" placeholder="Mot de passe" name="change_key1"   value={formValue.change_key1} onChange={changeInput}/>
        </p>

         <button className="flixbutton button_Active" onSubmit={confirmSubmit} >Confirme Email</button>      
      
        </form>
    </div>
    </div>
  );
}

export default Confirme;


