//import logo from '';
import '../App.css';
import React from "react";
import ReactDOM from 'react-dom/client';


import NavBar from "../NavBar.js"; 
import HeadApps from "../HeadApps.js"; 
import {Link, NavLink} from  'react-router-dom';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';

// dropdown menu
import Dropdown from 'react-dropdown';
import 'react-dropdown/style.css';

// dropdown option
const options = [
  'Activer', 'Désactiver', 'Maintenir','Editer','Supprimer'
];
const defaultOption = options[0];

const style = {
  position: 'absolute',
  top: '20%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
//  width: 700,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};


function Flixrooms() 
{   


  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);


  return (
      <div className="group_div">
      <HeadApps></HeadApps>
      <NavBar></NavBar>
      <div className="grid-10 apps_container">
      
      <div className="group_div">
        <div className="grid-12">
          <div className="grid-1">
            <button className="flix_btadd_item" onClick={handleOpen}><i className="demo-icon icon-plus-circled"></i><span>Nouvelle FlixRoom</span></button>
          </div>
          <div className="grid-10"></div>
        </div>
        <div className="grid-12">
        <ul className="flixTab_onglet">
          <li className=""><NavLink to="/Tableaubord" className="active_onglet"><span>Toutes</span></NavLink></li>
          <li><NavLink to="/Tableaubord"><span>Active</span></NavLink></li>
          <li><NavLink to="/Tableaubord"><span>Désactivées</span></NavLink></li>
          <li><NavLink to="/Tableaubord"><span>Encours</span></NavLink></li>
          <li><NavLink to="/Tableaubord"><span>Maintenues</span></NavLink></li>
          <li><NavLink to="/Tableaubord"><span>Supprimées</span></NavLink></li>
          <li><NavLink to="/Tableaubord"><span>Rapports</span></NavLink></li>
        </ul>
        </div>
        <div className="grid-12">
          <div className="flix_pagine_ul">
            <button><i className="demo-icon icon-left-open-4"></i></button>
            <span className="flix_pagine_result">1</span>/<span className="flix_pagine_result">2</span>
            <button><i className="demo-icon icon-right-open-4"></i></button>
          </div>
          <div className="flix_page_search ">
                <div className="flix_page_input"><i className="demo-icon icon-search-8"></i><input type="text" placeholder="Recherche" /></div>
                <div className="flix_page_input"><i className="demo-icon icon-calendar-8"></i><input type="text" placeholder="Filtre Date" /></div>
                <div className="flix_page_input"><i className="demo-icon icon-location-3"></i><input type="text" placeholder="Filtre Lieux" /></div>
          </div>
        </div>
      </div>

          <div className="group_div flixitem_group">
             <div className="flix_item flixroom_item">
                <div className="grid-12 flixroom_title_item">
                  FLIXROMM GENESIS 1
                </div>

                  <div className="flixroom_code_item"><span>FX-CERT109080</span></div>
                <div className="grid-12">
                  <div className="flixroom_status_item"><span className="flix_encours">Encours</span></div>
                </div>
                <div className="flixroom_adress_item"><i className="demo-icon icon-location-3"></i><span>47, Cantersteen  B.P: 1000 Bruxelles-Belgique</span></div>

                <div className="group_div flix_group1">
                  <div className="flix_date_expire"><i className="demo-icon icon-clock-7"></i><span><small>Expire</small>27/01/2031</span></div>
                  <div className="flix_date_create"><i className="demo-icon icon-calendar-empty"></i><span><small>Crée</small>27/01/2031</span></div>
                </div>
                <div className="group_div flix_group">
              
                      <div className="flixroom_avatar"><div className="flix_avatar_small" ></div></div>
                      <div className="flixroom_name"><small>Propriétaire</small><span>Dalice J.</span></div>
                  

                  </div>

                <div className="group_div flix_footer_item">
                      <Dropdown options={options}   onChange={this} placeholder="Action" className="flix_dropdown_action" />
                      <NavLink to="/FlixroomsVue"  className="flix_see_item">Voir Plus</NavLink>
                    
                  </div> 
             </div> 


           
             <div className="flix_item flixroom_item">
                <div className="grid-12 flixroom_title_item">
                  FLIXROMM GENESIS 2
                </div>

                  <div className="flixroom_code_item"><span>FX-CERT109080</span></div>
                <div className="grid-12">
                  <div className="flixroom_status_item"><span className="flix_active">Active</span></div>
                </div>
                <div className="flixroom_adress_item"><i className="demo-icon icon-location-3"></i><span>47, Cantersteen  B.P: 1000 Bruxelles-Belgique</span></div>

                <div className="group_div flix_group1">
                  <div className="flix_date_expire"><i className="demo-icon icon-clock-7"></i><span><small>Expire</small>27/01/2031</span></div>
                  <div className="flix_date_create"><i className="demo-icon icon-calendar-empty"></i><span><small>Crée</small>27/01/2031</span></div>
                </div>
                <div className="group_div flix_group">
              
                      <div className="flixroom_avatar"><div className="flix_avatar_small" ></div></div>
                      <div className="flixroom_name"><small>Propriétaire</small><span>Dalice J.</span></div>
                  

                  </div>

                <div className="group_div flix_footer_item">
                      <Dropdown options={options}  onChange={this} placeholder="Action" className="flix_dropdown_action" />
                      <NavLink to="/FlixroomsVue" className="flix_see_item">Voir Plus</NavLink>
                    
                  </div> 
             </div> 


             <div className="flix_item flixroom_item">
                <div className="grid-12 flixroom_title_item">
                  FLIXROMM GENESIS 3
                </div>

                  <div className="flixroom_code_item"><span>FX-CERT109080</span></div>
                <div className="grid-12">
                  <div className="flixroom_status_item"><span className="flix_maintenu">Maintenue</span></div>
                </div>
                <div className="flixroom_adress_item"><i className="demo-icon icon-location-3"></i><span>47, Cantersteen  B.P: 1000 Bruxelles-Belgique</span></div>

                <div className="group_div flix_group1">
                  <div className="flix_date_expire"><i className="demo-icon icon-clock-7"></i><span><small>Expire</small>27/01/2031</span></div>
                  <div className="flix_date_create"><i className="demo-icon icon-calendar-empty"></i><span><small>Crée</small>27/01/2031</span></div>
                </div>
                <div className="group_div flix_group">
              
                      <div className="flixroom_avatar"><div className="flix_avatar_small" ></div></div>
                      <div className="flixroom_name"><small>Propriétaire</small><span>Dalice J.</span></div>
                  

                  </div>

                <div className="group_div flix_footer_item">
                      <Dropdown options={options}   onChange={this} placeholder="Action" className="flix_dropdown_action" />
                      <NavLink to="/FlixroomsVue" className="flix_see_item">Voir Plus</NavLink>
                    
                  </div> 
             </div> 


             <div className="flix_item flixroom_item">
                <div className="grid-12 flixroom_title_item">
                  FLIXROMM GENESIS 4
                </div>

                  <div className="flixroom_code_item"><span>FX-CERT109080</span></div>
                <div className="grid-12">
                  <div className="flixroom_status_item"><span className="flix_annule">Annulée</span></div>
                </div>
                <div className="flixroom_adress_item"><i className="demo-icon icon-location-3"></i><span>47, Cantersteen  B.P: 1000 Bruxelles-Belgique</span></div>

                <div className="group_div flix_group1">
                  <div className="flix_date_expire"><i className="demo-icon icon-clock-7"></i><span><small>Expire</small>27/01/2031</span></div>
                  <div className="flix_date_create"><i className="demo-icon icon-calendar-empty"></i><span><small>Crée</small>27/01/2031</span></div>
                </div>
                <div className="group_div flix_group">
              
                      <div className="flixroom_avatar"><div className="flix_avatar_small" ></div></div>
                      <div className="flixroom_name"><small>Propriétaire</small><span>Dalice J.</span></div>
                  

                  </div>

                <div className="group_div flix_footer_item">
                      <Dropdown options={options}   onChange={this} placeholder="Action" className="flix_dropdown_action" />
                      <NavLink to="/FlixroomsVue"  className="flix_see_item">Voir Plus</NavLink>
                    
                  </div> 
             </div> 


             <div>

      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}  className="modal_addFlixRoom">
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Nouvelle FlixRoom
          </Typography>
          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
            <div className="group_div">
              <fieldset className="flix_fieldset">

              <legend>FlixRoom</legend>

                <div className="flix_model_input"><input type="text" placeholder="Code FlixRoom" /></div>
                <div className="flix_model_input"><input type="text" placeholder="Nom de la FlixRoom" /></div>

                <div className="flix_model_input1">
                <input type="text" placeholder="N° Rue" className="input20 margin_right20" />
                <input type="text" placeholder="Adresse" className="input70" />
                </div>

                <div className="flix_model_input"><input type="text" placeholder="Pays" /></div>

                <div className="flix_model_input1">
                <input type="text" placeholder="Code Postal" className="input45 margin_right20" />
                <input type="text" placeholder="Ville" className="input45" />
                </div>

              </fieldset>


              <fieldset className="flix_fieldset">

              <legend>FlixRoom Licence</legend>
                <div className="flix_model_input"><input type="text" placeholder="Numéro Licence" /></div>
                <div className="flix_model_input"><input type="text" placeholder="Date d'expiration" /></div>
                <div className="flix_model_input"><input type="text" placeholder="Propriétaire" /></div>
                <div className="flix_model_input"><input type="text" placeholder="Certificat Licence" /></div>
                <div className="flix_model_input"><input type="text" placeholder="Emetteur Licence" readonly/></div>
              </fieldset>


              <fieldset className="flix_fieldset">

              <legend>FlixRoom Equipement</legend>
                <div className="flix_model_input"><input type="text" placeholder="Caméra ID" /></div>
                <div className="flix_model_input"><input type="text" placeholder="Caméra Modele" /></div>
                <div className="flix_model_input"><input type="text" placeholder="panneau d'écran" /></div>
                <div className="flix_model_input"><input type="text" placeholder="Panneau modèle" /></div>
              </fieldset>


              <fieldset className="flix_fieldset">

              <legend>FlixRoom Réseaux</legend>
                <div className="flix_model_input"><input type="text" placeholder="Groupe" /></div>
                <div className="flix_model_input"><input type="text" placeholder="Adresse IP" /></div>
                <div className="flix_model_input"><input type="text" placeholder="Adresse IP Paneau d'écran" /></div>
                <div className="flix_model_input"><input type="text" placeholder="Point de streaming" /></div>
              </fieldset>
            </div>
          </Typography>
          <Typography id="modal-modal-footer" >
            <div className="group_div">
              <button className="flix_btsave">Enregistrer</button>
              <button className="flix_btcancel">Annuler</button>
            </div>
          </Typography>
        </Box>
      </Modal>
    </div>


          </div>

      </div>
      
      </div>
      );


   } 


export default Flixrooms;