
import '../App.css';
import { React, useSate,useState} from "react";
import ReactDOM from 'react-dom/client';
import { BrowserRouter, Route, Link } from "react-router-dom"; 
import {
  CognitoUserPool,
  CognitoUserAttribute,
} from "amazon-cognito-identity-js";

import { useNavigate } from "react-router-dom";
// configaration AWS Cognito

var AmazonCognitoIdentity = require('amazon-cognito-identity-js');

const REACT_APP_USERPOOL_ID="us-east-1_rOLcyaBUL";
const REACT_APP_APPCLIENT_ID="5bbpdtcioefd6fm3cnn1gct3rq";

const poolData = {
    UserPoolId: REACT_APP_USERPOOL_ID,
    ClientId: REACT_APP_APPCLIENT_ID,
  };

const UserPool = new CognitoUserPool(poolData);


function Register()
{    

const [formValue, setFormValue]=useState({flixname:'',flixsurname:'',flixphone:'',flixuser:'',flixkey1:'',flixkey2:''});

const navigate = useNavigate();

const handleInput = (e) =>{
const {name,value}=e.target;
  setFormValue({...formValue,[name]:value});
 }

const handleSubmit = async(e)=>{
e.preventDefault();
const allInputValue={flixname:formValue.flixname,flixsurname:formValue.flixsurname,flixphone:formValue.flixphone,flixuser:formValue.flixuser,flixkey1:formValue.flixkey1,flixkey2:formValue.flixkey2};

console.log(allInputValue);
    const email = allInputValue.flixuser.trim();
    const password1 = allInputValue.flixkey1.trim();
    const phone = allInputValue.flixphone;


    const attributeList = [
  
  new CognitoUserAttribute({
        Name: "name",
        Value: allInputValue.flixname,
      })
    ];

  const receivedNewUser = await   UserPool.signUp(email, password1, attributeList, null, (err, result) => {
      if (err) {
        console.log(err);
        return;
      }
      console.log(result);
      navigate("/");
    });




}
  return (
    <div className="container">
    <div className="App flix_login">
      <header className="App-header">
        <img src="./logoflix.svg" className="App-logo" alt="logo" />
        <h2>Flix<span>Admin</span> <span className="flix_grisTxt">Platform</span></h2>
        <form onSubmit={handleSubmit}>
        <p>
         <input type="text" placeholder="Nom" name="flixname" value={formValue.flixname} onChange={handleInput} />
        </p>
        <p>
         <input type="text" placeholder="Prenom" name="flixsurname" value={formValue.flixsurname} onChange={handleInput} />
        </p>
        <p>
         <input type="text" placeholder="Mobile" name="flixphone" value={formValue.flixphone} onChange={handleInput} />
        </p>
        <p>
         <input type="text" placeholder="E-mail" name="flixuser" value={formValue.flixuser} onChange={handleInput} />
        </p>
        <p>
         <input type="password" placeholder="Mot de passe" name="flixkey1"  value={formValue.flixkey1} onChange={handleInput}/>
        </p>

        <p>
         <input type="password" placeholder="Confirme Mot de passe" name="flixkey2"  value={formValue.flixkey2} onChange={handleInput}/>
        </p>
   
         <button className="flixbutton button_Active" onSubmit={handleSubmit}>Créer un compte</button>
      
        </form>
      </header>
    </div>
    </div>
    );


   } 

export default Register;

