//import logo from '';
import './App.css';
import React from "react"; 
import {Link} from  'react-router-dom';


function HeadApps() 
{   

 const flixAvatar = {
      color: "white",
      backgroundColor: "violet",
      padding: "10px",
      fontFamily: "Arial"
    };
  return (
      <div className="group_div flix_head">
      <div className="grid-2">
          <img src="logo_admin.svg" className="flix_logo float_left"/><span className="flix_grisTxt flixText">Platform</span>
      </div>
      <div className="grid-5 flix_search_head"> 
        <div className="grid-4"><input type="text" placeholder="Recherche" /></div>
      </div>
      <div className="grid-1 flix_notify">

      </div>
      <div className="flix_user"><div className="flix_user_top" ><div className="flix_avatar_small" style={flixAvatar}></div><button className=""><span>Thierry Essomba </span><i className="demo-icon icon-down-open-2"></i></button></div>
        
      </div>
      </div>); 
    } 

export default HeadApps;