//import logo from '';
import '../App.css';
import React from "react";
import ReactDOM from 'react-dom/client';

import NavBar from "../NavBar.js"; 
import HeadApps from "../HeadApps.js"; 
import {Link, NavLink} from  'react-router-dom';



function Recommandation() 
{   
  return (
      <div class="group_div">
      <HeadApps></HeadApps>
      <NavBar></NavBar>
      <div class="grid-10 apps_container">

      <div className="group_div">
        <div className="grid-12">
          <div className="grid-2">
            <button className="flix_btadd_item"><i className="demo-icon icon-plus-circled"></i><span>Nouvelle Recommandation</span></button>
          </div>
          <div className="grid-10"></div>
        </div>
        <div className="grid-12">
        <ul className="flixTab_onglet">
          <li><NavLink to="/Tableaubord"><span>Toutes</span></NavLink></li>
          <li><NavLink to="/Tableaubord"><span>Effectuées</span></NavLink></li>
          <li><NavLink to="/Tableaubord"><span>En cours</span></NavLink></li>
          <li><NavLink to="/Tableaubord"><span>Annulées</span></NavLink></li>
        </ul>
        </div>
        <div className="grid-12">
          <div className="flix_pagine_ul">
            <button><i className="demo-icon icon-left-open-4"></i></button>
            <span className="flix_pagine_result">1</span>/<span className="flix_pagine_result">2</span>
            <button><i className="demo-icon icon-right-open-4"></i></button>
          </div>
          <div className="flix_page_search ">
                <div className="flix_page_input"><i className="demo-icon icon-search-8"></i><input type="text" placeholder="Mot clef" /></div>
                <div className="flix_page_input"><i className="demo-icon icon-calendar-8"></i><input type="text" placeholder="Filtre Date" /></div>
                <div className="flix_page_input"><i className="demo-icon icon-user-1"></i><input type="text" placeholder="Filtre Profils" /></div>
          </div>
        </div>
      </div>

          <div className="">
            Recommandation
          </div>

      </div>
      </div>
      );


   } 


export default Recommandation;