import './App.css';

import { React, useSate,useState} from "react";

import { CognitoIdentityProviderClient, InitiateAuthCommand,  } from "@aws-sdk/client-cognito-identity-provider"; // ES Modules import

import {  CognitoUserPool,  CognitoUserAttribute, CognitoUser, } from 'amazon-cognito-identity-js';

import {Link, NavLink} from  'react-router-dom';

import * as Yup from 'yup';
import { useNavigate } from "react-router-dom";
//import { useDispatch } from "react-redux";


// Cognito variable userPool
var AmazonCognitoIdentity = require('amazon-cognito-identity-js');
//const REACT_APP_USERPOOL_ID="eu-north-1_OSOHqE2cX";
//const REACT_APP_APPCLIENT_ID="2bj4vhcdo861u3g4v5rsle3vj0";


//const REACT_APP_USERPOOL_ID="eu-north-1_0XIZXsZwd";
//const REACT_APP_APPCLIENT_ID="3mv4ecl6r6m8ulgtm03slk8j3d";

// FOR DALICE
const REACT_APP_USERPOOL_ID="us-east-1_rOLcyaBUL";
const REACT_APP_APPCLIENT_ID="5bbpdtcioefd6fm3cnn1gct3rq";

const userPool = new CognitoUserPool({
  UserPoolId: REACT_APP_USERPOOL_ID,
  ClientId: REACT_APP_APPCLIENT_ID,
});

const resetStep=0;


function App() {

 // déclaration des valeurs
 // flixuser: username, flixkey:password
 // Global value form 
 const [formValue, setFormValue]=useState({flixuser:'',flixkey:''});

// get Value input form
const handleInput = (e) =>{
const {name,value}=e.target;
  setFormValue({...formValue,[name]:value});
 }

// submit form login
 const navigate = useNavigate();
 var sessionUserAttributes;


const handleSubmit = async(e)=>{
e.preventDefault();
const allInputValue={flixuser:formValue.flixuser,flixkey:formValue.flixkey};



// Cognito user POST
    const cognitoUser = new CognitoUser({
      Username: allInputValue.flixuser,
      Pool: userPool,
    });

 const authenticationDetails = new AmazonCognitoIdentity.AuthenticationDetails({
      Username: allInputValue.flixuser,
      Password: allInputValue.flixkey,
    });


  cognitoUser.authenticateUser(authenticationDetails, {
        onSuccess: function (result) {
            var accessToken = result.getAccessToken().getJwtToken();

            /* Use the idToken for Logins Map when Federating User Pools with identity pools or when passing through an Authorization Header to an API Gateway Authorizer */
            var idToken = result.idToken.jwtToken;
            console.log(idToken);

            navigate("/Tableaubord");

        },onFailure: function(err) {
           console.log(err);
            if(err=="UserNotConfirmedException: User is not confirmed."){
              console.log("Merci de confirmer votre email");
            navigate("/confirme?email="+allInputValue.flixuser);
            }
        },

});

/**
    cognitoUser.authenticateUser(authenticationDetails, {
      onSuccess: (result) => {
        console.log(result);
      },
      onFailure: (err) => {
          console.log("Authenticate user failure");
          console.log(err);
          
     },
      newPasswordRequired: (userAttributes) => {
         delete userAttributes.email_verified;
         delete userAttributes.phone_number_verified;
         delete userAttributes.name;
         const newPw="Fl1xt@l3nt00";

        console.log(userAttributes);
      }
      
    });

    */

}




  return (
          <div className="container">
          <div className="App flix_login">
            <header className="App-header">
              <img src="./logoflix.svg" className="App-logo" alt="logo" />
              <h2>Flix<span>Admin </span><span className="flix_grisTxt">Platform</span></h2>

              <form onSubmit={handleSubmit}>
              <p>
               <input type="text" placeholder="E-mail" name="flixuser" value={formValue.flixuser} onChange={handleInput} />
              </p>
              <p>
               <input type="password" placeholder="Mot de passe" name="flixkey"  value={formValue.flixkey} onChange={handleInput}/>
              </p>

              <p>
              <NavLink to="./Forgetpass"><i className="demo-icon  icon-right-open-4"></i> Identifiants oubliés</NavLink>
              </p>
         
               <button className="flixbutton button_Active" onSubmit={handleSubmit}>Connexion</button>
              <NavLink to="./Register"><i className="demo-icon  icon-right-open-4"></i> Crée un compte utilisateur</NavLink>
            
              <p><br />
              <a href="mailto:connect@flixtalent.com"><i className="demo-icon icon-mail-3"></i> connect@flixtalent.com</a>
              </p>
              </form>
              
            </header>
          </div>
          </div>
        );
      
}

export default App;
