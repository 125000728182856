//import logo from '';
import '../App.css';
import React from "react";
import ReactDOM from 'react-dom/client';

import NavBar from "../NavBar.js"; 
import HeadApps from "../HeadApps.js"; 
import {Link, NavLink} from  'react-router-dom';


function Conversation() 
{   
  return (
      <div className="group_div">
      <HeadApps></HeadApps>
      <NavBar></NavBar>
      <div className="grid-10 apps_container">

      <div className="group_div">
        <div className="grid-12">
          <div className="grid-2">
            <button className="flix_btadd_item"><i className="demo-icon icon-plus-circled"></i><span>Nouvelle Conversation</span></button>
          </div>
          <div className="grid-10"></div>
        </div>
        <div className="grid-12">
        <ul className="flixTab_onglet">
          <li><NavLink to="/Tableaubord"><span>Toutes</span></NavLink></li>
          <li><NavLink to="/Tableaubord"><span>Reservées</span></NavLink></li>
          <li><NavLink to="/Tableaubord"><span>En cours</span></NavLink></li>
          <li><NavLink to="/Tableaubord"><span>Terminées</span></NavLink></li>
          <li><NavLink to="/Tableaubord"><span>Annulées</span></NavLink></li>
        </ul>
        </div>
        <div className="grid-12">
          <div className="flix_pagine_ul">
            <button><i className="demo-icon icon-left-open-4"></i></button>
            <span className="flix_pagine_result">1</span>/<span className="flix_pagine_result">2</span>
            <button><i className="demo-icon icon-right-open-4"></i></button>
          </div>
          <div className="flix_page_search ">
                <div className="flix_page_input"><i className="demo-icon icon-search-8"></i><input type="text" placeholder="Code Conversation" /></div>
                <div className="flix_page_input"><i className="demo-icon icon-calendar-8"></i><input type="text" placeholder="Filtre Date" /></div>
                <div className="flix_page_input"><i className="demo-icon icon-location-3"></i><input type="text" placeholder="Filtre FlixRoom" /></div>
          </div>
        </div>
      </div>

          <div className="flixitem_group">
            
             <div className="flix_item conversation_item">
                  <div className="flix_group_interaction">
                        <div className="group_div float_left width30">
                            <div className="flixroom_avatar"><div className="flix_avatar_small" ></div></div>
                            <div className="flixroom_name"><small>FlixAgent</small><span>Dalice J.</span></div>
                        </div>

                      <div className="float_left flix_icon_camera"><i className="demo-icon  icon-video-chat"></i></div>

                        <div className="group_div float_left width30">
                            <div className="flixroom_avatar"><div className="flix_avatar_small" ></div></div>
                            <div className="flixroom_name"><small>Talent</small><span>Dalice J.</span></div>
                        </div>

                  </div>
                <div className="group_div ">
                  <div className="float_left">
                    <div className="grid-12 flixroom_title_item">
                  Conversation
                </div>

                  <div className="flixroom_code_item"><span>FX-CERT109080</span></div>


                  </div>
                  <div className="float_right flix_group_conversation"><span className="flix_pret">Prête</span></div>
                </div>
                <div className="">
                  <div className="group_div flix_group_converse">
                    <div className="flix_lieu_item"><i className="demo-icon icon-location-3"></i><span>FlixRoom Auberghem</span></div>
                    <div className="flix_date_expire"><i className="demo-icon icon-clock-7"></i><span>90min</span></div>
                    <div className="flix_date_create"><i className="demo-icon icon-calendar-empty"></i><span>27/01/2031 11:22:30</span></div>
                  </div>
                </div>
                <div className="flix_paragraph">
                  <p>
                    Bon retour sur la platorme Flix; Des personnes attendent l’impact positif dans leurs quotidiens.
                  </p>
                </div>


                <div className="">
                       <button className="flix_see_item_left">Commencer</button>
                      <button className="flix_btcancel">Annuller</button>
                </div> 
             </div> 



            
             <div className="flix_item conversation_item">
                  <div className="flix_group_interaction">
                        <div className="group_div float_left width30">
                            <div className="flixroom_avatar"><div className="flix_avatar_small" ></div></div>
                            <div className="flixroom_name"><small>FlixAgent</small><span>Dalice J.</span></div>
                        </div>

                      <div className="float_left flix_icon_camera"><i className="demo-icon  icon-video-chat"></i></div>

                        <div className="group_div float_left width30">
                            <div className="flixroom_avatar"><div className="flix_avatar_small" ></div></div>
                            <div className="flixroom_name"><small>Talent</small><span>Dalice J.</span></div>
                        </div>

                  </div>
                <div className="group_div ">
                  <div className="float_left">
                    <div className="grid-12 flixroom_title_item">
                  Conversation
                </div>

                  <div className="flixroom_code_item"><span>FX-CERT109080</span></div>


                  </div>
                  <div className="float_right flix_group_conversation"><span className="flix_encours">Encours</span></div>
                </div>

                <div className="">
                  <div className="group_div flix_group_converse">
                    <div className="flix_lieu_item"><i className="demo-icon icon-location-3"></i><span>FlixRoom Auberghem</span></div>
                    <div className="flix_date_expire"><i className="demo-icon icon-clock-7"></i><span>90min</span></div>
                    <div className="flix_date_create"><i className="demo-icon icon-calendar-empty"></i><span>27/01/2031  11:22:30</span></div>
                  </div>
                </div>

                <div className="flix_paragraph">
                  <p>
                    Bon retour sur la platorme Flix; Des personnes attendent l’impact positif dans leurs quotidiens.
                  </p>
                </div>


                <div className="">
                       <button className="flix_bt"><i className="demo-icon icon-clock-7"></i>86:30</button>
                      <button className="flix_btcancel">Annuller</button>
                </div> 
             </div> 


            
             <div className="flix_item conversation_item">
                  <div className="flix_group_interaction">
                        <div className="group_div float_left width30">
                            <div className="flixroom_avatar"><div className="flix_avatar_small" ></div></div>
                            <div className="flixroom_name"><small>FlixAgent</small><span>Dalice J.</span></div>
                        </div>

                      <div className="float_left flix_icon_camera"><i className="demo-icon  icon-video-chat"></i></div>

                        <div className="group_div float_left width30">
                            <div className="flixroom_avatar"><div className="flix_avatar_small" ></div></div>
                            <div className="flixroom_name"><small>Talent</small><span>Dalice J.</span></div>
                        </div>

                  </div>
                <div className="group_div ">
                  <div className="float_left">
                    <div className="grid-12 flixroom_title_item">
                  Conversation
                </div>

                  <div className="flixroom_code_item"><span>FX-CERT109080</span></div>


                  </div>
                  <div className="float_right flix_group_conversation"><span className="flix_maintenu">Réservée</span></div>
                </div>
              
                <div className="">
                  <div className="group_div flix_group_converse">
                    <div className="flix_lieu_item"><i className="demo-icon icon-location-3"></i><span>FlixRoom Auberghem</span></div>
                    <div className="flix_date_expire"><i className="demo-icon icon-clock-7"></i><span>90min</span></div>
                    <div className="flix_date_create"><i className="demo-icon icon-calendar-empty"></i><span>27/01/2031  11:22:30</span></div>
                  </div>
                </div>

                <div className="flix_paragraph">
                  <p>
                    Bon retour sur la platorme Flix; Des personnes attendent l’impact positif dans leurs quotidiens.
                  </p>
                </div>


                <div className="">
                       <button className="flix_see_item_left">Ouvrir</button>
                      <button className="flix_btcancel">Annuller</button>
                </div> 
             </div> 


            
             <div className="flix_item conversation_item">
                  <div className="flix_group_interaction">
                        <div className="group_div float_left width30">
                            <div className="flixroom_avatar"><div className="flix_avatar_small" ></div></div>
                            <div className="flixroom_name"><small>FlixAgent</small><span>Dalice J.</span></div>
                        </div>

                      <div className="float_left flix_icon_camera"><i className="demo-icon  icon-video-chat"></i></div>

                        <div className="group_div float_left width30">
                            <div className="flixroom_avatar"><div className="flix_avatar_small" ></div></div>
                            <div className="flixroom_name"><small>Talent</small><span>Dalice J.</span></div>
                        </div>

                  </div>
                <div className="group_div ">
                  <div className="float_left">
                    <div className="grid-12 flixroom_title_item">
                  Conversation
                </div>

                  <div className="flixroom_code_item"><span>FX-CERT109080</span></div>


                  </div>
                  <div className="float_right flix_group_conversation"><span className="flix_active">Terminée</span></div>
                </div>
                <div className="">
                  <div className="group_div flix_group_converse">
                    <div className="flix_lieu_item"><i className="demo-icon icon-location-3"></i><span>FlixRoom Auberghem</span></div>
                    <div className="flix_date_expire"><i className="demo-icon icon-clock-7"></i><span>90min</span></div>
                    <div className="flix_date_create"><i className="demo-icon icon-calendar-empty"></i><span>27/01/2031  11:22:30</span></div>
                  </div>
                </div>

                <div className="flix_paragraph">
                  <p>
                    Bon retour sur la platorme Flix; Des personnes attendent l’impact positif dans leurs quotidiens.
                  </p>
                </div>


                <div className="">
                       <button className="flix_see_item_left">Voir les détails</button>
                </div> 
             </div> 

                  <div className="flix_item conversation_item">
                  <div className="flix_group_interaction">
                        <div className="group_div float_left width30">
                            <div className="flixroom_avatar"><div className="flix_avatar_small" ></div></div>
                            <div className="flixroom_name"><small>FlixAgent</small><span>Dalice J.</span></div>
                        </div>

                      <div className="float_left flix_icon_camera"><i className="demo-icon  icon-video-chat"></i></div>

                        <div className="group_div float_left width30">
                            <div className="flixroom_avatar"><div className="flix_avatar_small" ></div></div>
                            <div className="flixroom_name"><small>Talent</small><span>Dalice J.</span></div>
                        </div>

                  </div>
                <div className="group_div ">
                  <div className="float_left">
                    <div className="grid-12 flixroom_title_item">
                  Conversation
                </div>

                  <div className="flixroom_code_item"><span>FX-CERT109080</span></div>


                  </div>
                  <div className="float_right flix_group_conversation"><span className="flix_annule">Annulée</span></div>
                </div>
                <div className="">
                  <div className="group_div flix_group_converse">
                    <div className="flix_lieu_item"><i className="demo-icon icon-location-3"></i><span>FlixRoom Auberghem</span></div>
                    <div className="flix_date_expire"><i className="demo-icon icon-clock-7"></i><span>90min</span></div>
                    <div className="flix_date_create"><i className="demo-icon icon-calendar-empty"></i><span>27/01/2031  11:22:30</span></div>
                  </div>
                </div>
                <div className="flix_paragraph">
                  <p>
                    Bon retour sur la platorme Flix; Des personnes attendent l’impact positif dans leurs quotidiens.
                  </p>
                </div>


                <div className="">
                       <button className="flix_see_item_left">Voir les détails</button>
                </div> 
             </div> 

          </div>

      </div>
      </div>
      );


   } 


export default Conversation;